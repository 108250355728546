const PROJECT = {
	transportType: 'bus_train',
	context: 'AMN',
	defaultLocale: 'en',
	includeDefaultLocaleInPath: true,
	locales: [
		'en',
		'en-GB',
		'en-CA',
		'en-ZA',
		'es-MX',
		'es',
		'it',
		'fr',
		'de',
		'pl',
		'pt',
		'pt-BR',
	],
	regions: {
		en: 'US',
		es: 'ES',
		it: 'IT',
		fr: 'FR',
		de: 'DE',
		pl: 'PL',
		pt: 'PT',
		'pt-BR': 'BR',
		'es-MX': 'MX',
		'en-CA': 'CA',
		'en-GB': 'GB',
		'en-ZA': 'ZA',
	},
	currencies: {
		en: 'USD',
		es: 'EUR',
		it: 'EUR',
		fr: 'EUR',
		de: 'EUR',
		pl: 'PLN',
		pt: 'EUR',
		'pt-BR': 'BRL',
		'es-MX': 'MXN',
		'en-CA': 'CAD',
		'en-GB': 'GBP',
		'en-ZA': 'ZAR',
	},
	metricUnits: {
		en: 'mi',
		es: 'km',
		it: 'km',
		fr: 'km',
		de: 'km',
		pl: 'km',
		pt: 'km',
		'pt-BR': 'km',
		'es-MX': 'km',
		'en-CA': 'km',
		'en-GB': 'km',
		'en-ZA': 'km',
	},
	hasCustomLocaleFiles: false,
	useLocationLongNames: false,
	useLocationOldIds: false,
	useDatabaseFilterByProjectId: false,
	database: {
		companies: 'companies_NTRIP',
		stations: 'stations_NTRIP',
		schedules: 'schedules_NTRIP',
		checklists: 'checklists_NTRIP',
		contents: 'contents_NTRIP',
		faq: 'faq_NTRIP',
		locations: 'locations_NTRIP_V3',
		reviews: 'reviews_busbuster',
		routes: 'routes_NTRIP_V3',
		seo: 'seo_NTRIP',
		tips: 'tips_NTRIP',
		topRoutes: 'top_routes_NTRIP_V3',
	},
	defaultCrawlerSourcerId: null, //'us-flixbus',
	defaultCurrency: 'USD',
	hrefLanguages: [
		{
			lng: 'en',
			hrefLang: 'en-us',
		},
		{
			lng: 'es-MX',
			hrefLang: 'es-mx',
		},
		{
			lng: 'en-GB',
			hrefLang: 'en-gb',
		},
		{
			lng: 'en-CA',
			hrefLang: 'en-ca',
		},
		{
			lng: 'en-ZA',
			hrefLang: 'en-za',
		},
		{
			lng: 'es',
			hrefLang: 'es',
		},
		{
			lng: 'it',
			hrefLang: 'it',
		},
		{
			lng: 'fr',
			hrefLang: 'fr',
		},
		{
			lng: 'de',
			hrefLang: 'de',
		},
		{
			lng: 'pl',
			hrefLang: 'pl',
		},
		{
			lng: 'pt',
			hrefLang: 'pt',
		},
		{
			lng: 'pt-BR',
			hrefLang: 'pt-br',
		},
	],
	pathnames: [
		'/',
		'/privacy',
		'/terms',
		'/search',
		'/about',
		'/contact',
		'/stations',
		'/stations/[stationId]',
		'/companies',
		'/companies/[companyId]',
		'/destinations/[destinationId]',
		'/tickets',
		'/tickets/[routeId]',
		'/train',
		'/train/[routeId]',
		'/[routeTransportType]',
		'/[routeTransportType]/[routeId]',
		'/schedules/[routeId]',
		'/help-center',
		'/help-center/[companyId]',
		'/help-center/[companyId]/[slug]',
		'/things-to-do',
		'/things-to-do/[destinationId]',
		'/things-to-do/[destinationId]/[categoryId]',
		'/things-to-do/[destinationId]/[categoryId]/[attractionId]',
	],
	routes: {
		'/': {
			en: '/en',
			'es-MX': '/es-MX',
		},
		'/privacy': {
			en: '/en/privacy',
			'es-MX': '/es-MX/privacidad',
		},
		'/terms': {
			en: '/en/terms',
			'es-MX': '/es-MX/condiciones',
		},
		'/search': {
			en: '/en/search',
			'es-MX': '/es-MX/busqueda',
		},
		'/about': {
			en: '/en/about',
			'es-MX': '/es-MX/sobre',
		},
		'/contact': {
			en: '/en/contact',
			'es-MX': '/es-MX/contacto',
		},
		'/stations/[stationId]': {
			en: '/en/bus-stations/:stationId',
			'es-MX': '/es-MX/estaciones/:stationId',
		},
		'/stations': {
			en: '/en/bus-stations',
			'es-MX': '/es-MX/estaciones',
		},
		'/destinations/[destinationId]': {
			en: '/en/bus-to-:destinationId',
			'es-MX': '/es-MX/autobus-a-:destinationId',
		},
		'/tickets': {
			en: '/en/bus-tickets',
			'es-MX': '/es-MX/boletos-de-autobus',
		},
		'/tickets/[routeId]': {
			en: '/en/bus-tickets-:routeId',
			'es-MX': '/es-MX/autobus-:routeId',
		},
		'/companies/[companyId]': {
			en: '/en/:companyId-bus-tickets',
			'es-MX': '/es-MX/:companyId-boletos-de-autobus',
		},
		'/companies': {
			en: '/en/bus-companies',
			'es-MX': '/es-MX/companias',
		},
	},
	stats: {
		companies: '1.619',
		cities: '89.528',
		travellers: '10M',
	},
	defaultRegion: null,
	context: 'AMN',
	language: 'en',
	id: 'busbuster',
	domain: 'www.busbuster.com',
	name: 'BusBuster',
	legalName: 'BusBuster OU',
	foundingDate: '2015-03-19',
	email: 'admin@busbuster.com',
	phone: null,
	vatID: 'EE101862794',
	address: {
		city: 'Tallinn',
		region: 'North Estonia',
		country: 'EE',
		zip: '15551',
		street: 'Sepapaja 6',
	},
	description:
		'BusBuster helps you find everything about buses and trains all over the world. Tickets, schedules, prices, destinations, and information about the main companies and stations.',
	hasBlog: true,
	noIndex: false,
	cdn: 'https://d3fuv5nkmux689.cloudfront.net',
	facebookPixelId: null,
	facebook: 'https://www.facebook.com/thebusbuster',
	twitter: 'https://twitter.com/thebusbuster',
	youtube: 'https://www.youtube.com/@Busbuster',
	linkedIn: 'https://www.linkedin.com/company/busbuster/',
	googleAnalyticsId: 'G-9GVC16QGE2',
	googleTagManagerId: 'GTM-TRRWZF',
	googleMapsApiKey: 'AIzaSyBYozYVons1i0QYZOgnfUbxWVCguhHWnhk',
	bookingId: '800758',
	bing: {
		conversionId: '5201487',
	},
	adWords: {
		conversionId: null,
		conversionLabel: null,
	},
	adSense: {
		disabledLocales: [],
		accountId: 'pub-2949331628822281',
		searchId: 'partner-pub-2949331628822281:4764203029',
		fullWidth: false,
		enabled: true,
		csa: {
			route: '8162407211',
			schedule: '8162407211',
		},
		route: {
			ad1: '3725932456',
			ad2: '1239810852',
			ad3: '2716544055',
			ad4: '4193277259',
		},
		schedule: {
			ad1: '5670010454',
			ad2: '7146743656',
		},
		thingsToDo: {
			hero: 8697385691,
			sidebar: 6504906003,
			sections: 7384304028,
			items: 5191824334,
		},
		company: {
			hero: 2576943253,
			sidebar: 4053676457,
			routes: 5530409652,
			features: 4820089553,
			help_center: 9758814796,
			rating: 2752329410,
			sentiment: 5832012224,
			faqs: 7001861342,
			policies: 1766550808,
		},
		landing: {
			ad1: '7007142858',
			ad2: '8483876050',
			ad3: '9960609255',
		},
		video: 1474202497,
		anchor: 9750969763,
	},
};

module.exports.project = (() => {
	const project = PROJECT;
	if (process.env.NODE_ENV === 'development') {
		project.domain = 'localhost:3000';
	}
	for (const pathname of project.pathnames) {
		if (!project.routes[pathname]) {
			project.routes[pathname] = {};
		}
		for (const locale of project.locales) {
			if (project.routes[pathname][locale]) {
				continue;
			}

			const params = pathname.replace(/\[(\w+)\]/g, ':$1');
			project.routes[pathname][locale] = `/${locale}${
				params === '/' ? '' : params
			}`;
		}
	}

	return project;
})();
